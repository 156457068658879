import Typography from "../primitives/typography";
import {colors} from "../primitives/colors";
import React from "react";
import {breakpoints} from "../primitives/tokens";
import {ProductRenderDisplayVariant} from "../../importer/customizer/utils/get-variants-for-display";
import {css} from "@emotion/react";

const styles = {
    a1: css({
        marginTop: '24px',
        [breakpoints.mb]: {
            marginTop: '20px'
        }
    }),
    a2: css({
        marginTop: '24px',
        paddingTop: '12px',
        paddingBottom: '12px',
        borderTop: `1px solid ${colors.secondaryBeige4}`,
        borderBottom: `1px solid ${colors.secondaryBeige4}`,
        marginBottom: '12px',
        [breakpoints.mb]: {
            marginTop: '20px'
        }
    }),
    a3: css({
        display: 'flex',
        gap: '24px',
        marginBottom: '4px',
        [breakpoints.mb]: {
            gap: '12px'
        }
    }),
    a4: css({
        width: '36%',
        minWidth: '36%',
        [breakpoints.mb]: {
            width: '40%',
            minWidth: '40%',
        }
    }),
    a5: css({
        [breakpoints.mb]: {
            width: '50%'
        }
    }),
    a6: css({
        marginBottom: '32px'
    }),
    a7: css({
        display: 'flex',
        gap: '24px',
        alignItems: 'flex-end'
    }),
    a8: css({
        width: '36%',
        minWidth: '36%',
        [breakpoints.mb]: {
            width: '40%',
            minWidth: '40%',
        }
    }),
    a9: css({
        [breakpoints.mb]: {
            width: '50%'
        }
    })
}

const ColorVariantCard = ({ variant }: { variant: ProductRenderDisplayVariant }) => {
    return (
        <>
            <div css={styles.a1}>
                <Typography fontSize={{
                    dt: 'h4'
                }} color="neutral9" fontWeight="medium">
                    {variant?.fabricTitle}
                </Typography>
                <Typography fontSize={{
                    dt: 'h4'
                }} color="neutral9" fontWeight="light">
                    {variant?.productTitle}
                </Typography>
                <Typography fontSize={{
                    dt: 'p1'
                }} color="neutral9" fontWeight="light">
                    {variant?.colorTitle}
                </Typography>
            </div>
            <div css={styles.a2}>
                {variant?.keyFacts.map(({ key, value }) => (
                    <div css={styles.a3}>
                        <Typography fontSize={{
                            dt: 'p1'
                        }} color="shadesBlack" css={styles.a4}>
                            {key}
                        </Typography>
                        <Typography fontSize={{
                            dt: 'p1'
                        }} css={styles.a5} color="shadesBlack" fontWeight="light">
                            {value}
                        </Typography>
                    </div>
                ))}
            </div>
            <div css={styles.a6}>
                <div css={styles.a7}>
                    <Typography fontSize={{
                        dt: 'p1'
                    }} css={styles.a8} color="shadesBlack">
                        Quick Quote
                    </Typography>
                    <Typography css={styles.a9} fontSize={{
                        dt: 'h4'
                    }} color="shadesBlack">
                        {variant?.price}
                    </Typography>
                </div>
            </div>
        </>
    )
}

export default ColorVariantCard
